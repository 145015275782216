<template>
  <div>
    <div class="header-top">
      <img class="tp-img" src="../../assets/images/staff/zhaopin-ban.png" />
      <div class="tp-title">员工招聘</div>
      <div class="tp-ic"></div>
      <div class="tp-body">
        <div class="underline"></div>
      </div>
      <div class="tp-body-cn">诚聘！</div>
      <div class="tp-content">
        <div class="tp-format">
          <span class="recruit-title">1、总经理助理： 1名：</span><span
            class="recruit-content">负责总经理日常事务处理，负责总经理日常工作行程及出差等各项工作,负责公司商务接待，协助谈判等工作,辅助招投标工作,收集市场和客户信息,有环保行业经验优先,能适应出差,熟悉公关礼仪,具备优秀的文字写作和表达能力,具备良好的沟通协调能力，较强的统筹协调能力,会驾车。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">1、环保工程师： 1名：</span><span
            class="recruit-content">污水处理方案编写，污水调试方案编写，污水水质检测指导、工艺流程设计、安装图设计，管道图设计或提资、电气控制提资，环境工程专业,有机垃圾污水处理设计、安装、调试或运营经验,五年年以上相关工作经验，优秀应届毕业生也可考虑（研究生论文相关课题）,能够适应长期出差,有C证驾照。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">1、技术工程师： 1名：</span><span
            class="recruit-content">投标文件、技术文件的编辑及整理，配合商务部完成投标工作，技术方案的编写、技术部与商务部、工程部的文件交接、技术部文件的归档和整理,技术部完成工作总结（每周、月），未完成工作总结（每周）,热爱环保行业，有积极学习陌生行业的兴趣及能力,有一定有机垃圾行业的工作经验,具备相当的写作能力,熟练操作OFFICE办公软件,有驾照优先,年龄25-32，条件优秀可适当放宽。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">1、项目运营管理（厂长） 4名：</span><span
            class="recruit-content">主要负责有机垃圾处理项目的生产经营管理，有3年以上的餐厨、厨余垃圾项目的生产经营管理经验，本科及以上学历，环保、环境、电气、热能与动力、制冷、暖通、机械等相关专业。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">2、市场区域经理/市场拓展经理 5名：</span><span
            class="recruit-content">主要负责公司业务拓展及承接，有餐厨、厨余垃圾处理招投标经验，能够独立完成商务方案，要求具有一定的市场经验和人脉资源，有承接过项目经验者优先，能适应长期出差。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">3、商务技术 2名：</span><span
            class="recruit-content">主要负责市场信息的收集，投标文件的制作及标书中涉及的相应工作,要求具有大专及以上学历，环保、环境、电气、热能与动力、制冷、暖通、机械等相关专业、有经验者优先，具有良好的团队协作精神，沟通能力强，适应出差。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">4、污水处理工程师 2名：</span><span
            class="recruit-content">主要负责新建项目水处理的需求调查及方案编写，现场施工管理及运营调试工作。熟悉水处理工艺设计等工艺，一年或以上水处理行业工程设计经验，能够独立完成化工或水处理工艺设计工作，具有一定的现场经验。能熟练应用结构设计软件，熟练应AutoCAD、Office等应用软件。环境工程专业或给排水专业本科以上学历，沟通能力强，适应出差。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">5、设计工程师（工艺管道） 2名：</span><span
            class="recruit-content">主要负责本专业项目管道排布设计、绘制专业系统图、施工图、细化设计图及项目管理，负责关键管件清单提报及项目安装技术支持等工作；本岗位可以接受应届，化工、过程装备与控制，熟悉CAD/Plant3D等三维软件者优先。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">6、环保研发工程师（固废） 2名：</span><span
            class="recruit-content">主要根据客户要求，完成相关固废处理工艺开发及相关方案或研发报告的编写。提供工艺流程说明，协助工艺设计人员进行相关工艺设计。同时负责客户现场工艺调试等工作。要求具有化学、环境相关专业，研究生1年以上经验，本科2年以上相关工作经验。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">7、生物技术研发工程师 2人：</span><span
            class="recruit-content">主要负责运用生物技术及其产业化的原理、工艺技术过程和工程设计等基础理论，在生物技术与工程领域从事设计、生产、管理和新技术研究、新产品开发。要求已通过助理生物工程师资格认证者或者研究生以上或同等学历应届毕业生，本科以上或同等学历并从事相关工作一年以上者。</span>
        </div>
        <div class="tp-format">
          <span class="recruit-title">8、质量经理 1人：</span><span
            class="recruit-content">主要负责生产设备的产品质量管理，机械技术方案和技术图纸审核及机械相关技术难题解决及指导。要求具有10年以上机械设备研发、设计经验，全日制大专及以上学历，机械设计、机械制造及自动化相关专业。</span>
          <div class="format-post">岗位工作地点：扬州宝应小官庄</div>
        </div>
        <div class="tp-format">
          <span class="recruit-title">9、机械设计工程师 2名：</span><span
            class="recruit-content">主要负责对非标设备进行深化设计，对设计的产品进行跟踪、调试、并进一步完善。解决产品生产、组装、调试过程中的技术问题。要求机械设计自动化相关专业，5年以上工作经验；团队协作意识强，能吃苦耐劳。</span>
          <div class="format-post">岗位工作地点：扬州宝应小官庄</div>
        </div>
        <div class="tp-format">
          <span class="recruit-title">10、生产计划 2名：</span><span
            class="recruit-content">主要负责订单评审及出货安排。负责编制物料需求计划、车间生产计划，跟踪生产进度、物料进度，对各种数据进行汇总统计。要求具有1年以上钣金业务等相关工作经验。熟悉生产计划和统计管理等相关专业知识。具有较强的沟通能力，熟练使用office办公软件、ERP系统。</span>
          <div class="format-post">岗位工作地点：扬州宝应小官庄</div>
        </div>
        <div class="tp-format">
          <span class="recruit-title">11、仓管 2名：</span><span
            class="recruit-content">主要负责仓库日常收、发、存管理工作，做到帐、卡、单、物一致，妥善保管在库原材料和成品。信息系统数据的录入、填写和传递，相关单证、报表的整理和归档。定期与仓库核对数据并实地盘点，检查监督出、入库手续。要求具有非标自动化行业相关仓管管理经验，熟悉工厂物料和成品的工作流程,熟练使用office。</span>
          <div class="format-post">岗位工作地点：扬州宝应小官庄</div>
        </div>
        <div class="format-content">
          <img height="230px" src="../../assets/images/home/wx.png" />
        </div>
        <div class="endnote">联系人：韩女士（人事部）</div>
        <div class="endnote">电&nbsp;话：15366204535</div>
        <div class="endnote">邮&nbsp;箱：tengkanghb@aliyun.com</div>
        <div class="endnote">
          地&nbsp;址：苏州市吴中区东方大道988号江苏吴中大厦
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.header-top {
  min-width: 1250px;
  height: 100%;
  margin: auto;

  .tp-title {
    text-align: center;
    margin-top: 35px;
    color: #1f1f1f;
    font-size: 31px;
  }

  .tp-ic {
    width: 40px;
    height: 4px;
    margin: 20px auto;
    background: #8cc6ee;
  }

  .tp-content {
    width: 1000px;
    margin: 20px auto;
  }
}

.underline {
  width: 100%;
  height: 18px;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/zigongsi/i-title.png");
}

.tp-body {
  width: 560px;
  margin: auto;
}

.tp-img {
  height: 380px;
  width: 100%;
}

.tp-body-cn {
  margin: auto;
  width: 560px;
  text-align: center;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}

.recruit-title {
  color: #666666;
  line-height: 30px;
}

.recruit-content {
  color: #444444;
  font-size: 15px;
  line-height: 30px;
}

.tp-format {
  margin-top: 30px;

  .format-post {
    font-size: 15px;
    font-weight: 600;
    padding-top: 13px;
    color: #444444;
  }
}

.format-content {
  width: 224px;
  margin: 30px auto;
}

.endnote {
  margin-top: 14px;
  font-size: 16px;
  color: #444444;
  font-weight: 600;
}
</style>
